import React from "react"
import Layout from '../../components/layout-id'
import Head from '../../components/head'
import '../../styles/what-we-do.css'
import {Row, Col} from 'react-bootstrap'
import { Link } from 'gatsby'
import {graphql} from 'gatsby'


import rightArrow from '../../images/long-arrow-right.png'
import FarmerImg from '../../images/farmer-img.png'
import InputsCompanyImg from '../../images/inputs-company-img.png'
import CropInsuranceImg from '../../images/crop-insurance-img.png'
import FarmingCompanyImg from '../../images/farming-company-img.png'
import SeedProducerImg from '../../images/seed-producer-img.png'
import FinancialInstitution from '../../images/financial-support-img.png'
import GovernmentImg from '../../images/government-img.png'
import Vegetables from '../../images/vegetables.png'
import Cabbages from '../../images/cabbage.png'
import OrangeTree from '../../images/orange_tree.png'

export const query = graphql`{
  Vegetables: file(relativePath: {eq: "vegetables.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  Cabbages: file(relativePath: {eq: "cabbage.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
  OrangeTree: file(relativePath: {eq: "orange_tree.png"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, layout: FULL_WIDTH)
    }
  }
}
`

class WhatWeDo extends React.Component {

  /*constructor(props){
    super(props);
    
  }*/

  render(){

    return(
        <Layout>
          <Head title = "What We Do"/>
          
          <div id = "wwd-page-container">
            <div id = "wwd-section-1">
                <div id = "wwd-section-1-content">
                  <div id = "wwd-section-1-content-title">Pendekatan kami</div>
                  <div id = "wwd-section-1-content-paragraph">
                    Memberdayakan petani dan perusahaan untuk tumbuh lebih banyak, 
                    menjangkau lebih banyak, dan mendistribusikan dengan lebih baik. 
                </div>
              </div>
            </div>

            <div id = "wwd-page-nav">
              <div id = "wwd-nav-item-container">
                <div className = "wwd-nav-item nav-active">Pendekatan Kami</div>
                <div className = "wwd-nav-item">Mitra</div>
                <div className = "wwd-nav-item">Enterprise</div>
              </div>
            </div>

            <div id = "wwd-section-2">
              <div id = "wwd-section-2-content">
                  <div id = "section-2-content-title">
                    Mari kita bangun pertanian yang lebih tangguh dan berkelanjutan bersama.
                  </div>
                  <div id = "section-2-content-paragraph">
                    <p>
                        Populasi manusia terus meningkat menuju 10 miliar pada tahun 2050 (FAO) 
                        dan permintaan makanan meningkat dengan cepat. Semua di tengah-tengah 
                        perubahan iklim yang belum pernah terjadi sebelumnya yang akan membebani 
                        sistem yang paling tangguh sekalipun. Sistem pangan dan pertanian saat ini 
                        bertanggung jawab atas seperempat dari emisi gas rumah kaca dunia dan 70% 
                        dari penggunaan air tawar global. Sistem yang sama menghabiskan tanah, 
                        kualitas air, dan habitat alami di seluruh dunia. Pada saat yang sama, 
                        hingga 40% dari hasil global hilang karena penyakit tanaman dan hama setiap tahun. 
                        Ini merupakan ancaman bagi keamanan pangan global.
                    </p>

                    <p>
                        Jelas bahwa kita membutuhkan sistem pangan yang lebih tangguh dan berkelanjutan. 
                        Dan untuk mencapainya, kami menyadari bahwa diperlukan upaya kolektif dari berbagai 
                        pemangku kepentingan: universitas, lembaga pemerintah, dan masyarakat.
                    </p>
                  </div>
                </div>
            </div>

            <div id = "wwd-section-3">
              <div id = "wwd-section-3-content">
                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <img src = {FarmerImg} alt = "farmer-img"/>
                      <div className = "wwd-section-3-box-title">Petani</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <img src = {InputsCompanyImg} alt = "inputs-company-img"/>
                      <div className = "wwd-section-3-box-title">Input Perusahaan</div>
                  </div>
                </div>

                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <img src = {CropInsuranceImg} alt = "crop-insurance-img"/>
                      <div className = "wwd-section-3-box-title">Asuransi Tanaman</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <img src = {FarmingCompanyImg} alt = "Farming-company-img"/>
                      <div className = "wwd-section-3-box-title">Perusahaan Pertanian</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <img src = {SeedProducerImg} alt = "seed-produce-img"/>
                      <div className = "wwd-section-3-box-title">Produsen Benih</div>
                  </div>
                </div>

                <div className = "wwd-section-3-box-container">
                  <div className = "wwd-section-3-box">
                      <img src = {FinancialInstitution} alt = "financial-institution-img"/>
                      <div className = "wwd-section-3-box-title">Lembaga keuangan</div>
                  </div>
                  <div className = "wwd-section-3-box">
                      <img src = {GovernmentImg} alt = "inputs-company-img"/>
                      <div className = "wwd-section-3-box-title">Pemerintah</div>
                  </div>
                </div>
              </div>
            </div>

            <div id = "wwd-section-4">
              <div id = "wwd-section-4-header">
                <div id = "wwd-section-4-header-title">Memecahkan Pertanian Melalui Teknologi</div>
                <div id = "wwd-section-4-header-paragraph">
                    Kami percaya bahwa membuka kekuatan data dan teknologi dalam industri Pertanian dapat 
                    membantu kami menyediakan makanan untuk semua orang tanpa membahayakan generasi mendatang.
                </div>
              </div>

              <div id = "wwd-section-4-body">
                <div id = "wwd-section-4-body-card-container">
                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <img src = {FarmerImg} alt = "farmer-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Petani</div>
                    
                    <ul>
                      <li>
                        <span>Meningkatkan Pengetahuan</span>
                      </li>
                      <li>
                        <span>Mengurangi Risiko</span>
                      </li>
                      <li>
                        <span>Tingkatkan Hasil</span>
                      </li>
                      <li>
                        <span>Menginspirasi petani generasi selanjutnya</span>
                      </li>
                    </ul>

                  </div>

                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <img src = {FarmingCompanyImg} alt = "farming-company-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Perusahaan Pertanian</div>
                    
                    <ul>
                      <li>
                        <span>Biaya lebih rendah</span>
                      </li>
                      <li>
                        <span>Meningkatkan produktivitas</span>
                      </li>
                      <li>
                        <span>Operasi yang Efisien</span>
                      </li>
                    </ul>

                  </div>

                  <div className = "wwd-section-4-card">

                    <div className = "wwd-section-4-card-img">
                      <img src = {GovernmentImg} alt = "government-img"/>
                    </div>

                    <div className = "wwd-section-4-card-title">Pemerintahan</div>
                    
                    <ul>
                      <li>
                        <span>Kebijakan yang lebih baik</span>
                      </li>
                      <li>
                        <span>Memastikan Keamanan Pangan</span>
                      </li>
                      <li>
                        <span>Tingkatkan Kesejahteraan Petani</span>
                      </li>
                    </ul>

                  </div>

                </div>

                
              </div>

              <div id = "partnering-with-us">
                <Link to="/what-we-do" id = "partnering-with-us-link">
                    Pelajari Lebih Lanjut Tentang Bermitra Dengan Kami
                  <img src = {rightArrow} id = "right-arrow" alt = "right-arrow" width = "28px"/>
                </Link>
              </div>

            </div>

            <div id = "wwd-section-5">
                <Row>
                  <Col md = {{span : 5, offset : 1}} id = "section-6-col-1">
                    <div id = "section-6-col-1-title-1">Tingkatkan Hasil dan Produktivitas Hari Ini</div>
                    
                    <Link to = "contact-us" id = "wwd-section-5-button">Talk To Us</Link>
                      
                  </Col>

                  <Col md = {{span : 4, offset : 2}} id = "wwd-section-5-col-2">
                      <img src = {Vegetables} alt = "vegetables-img" id = "vegetables-img"/>
                      <img src = {OrangeTree} alt = "OrangeTree-img" id = "orange-tree-img"/>
                      <img src = {Cabbages} alt = "cabbages-img" id = "cabbages-img"/>
                  </Col>
                </Row>
              </div>

          </div>
        </Layout>
    ) 
  }
}

export default WhatWeDo;